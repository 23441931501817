@media (prefers-color-scheme: dark) {

  :root {
    --bs-body-bg: #3C3F41;
    --bs-body-color: #BBBBBB;
  }

  a {
    color: #AB5232;
    &:hover {
      color: #c96d3e;
    }
  }

  .form-control {
    background-color: #45494A;
    border-color: #646464;
    color: #BFBFBF;
    &:focus {
      background-color: #45494A;
      border-color: #646464;
      color: #BFBFBF;
    }
    &:disabled {
      background-color: #565a5b;
      border-color: #646464;
      color: #BFBFBF;
    }
  }

  select.form-control {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    background: url(/assets/lib/font-awesome/svgs/solid/chevron-down.svg) no-repeat right 0.3em center;
    background-size: 25px 20px;

    padding-right: 30px;
  }

  .input-group-text {
    background-color: #343738;
    border-color: #646464;
    color: #BFBFBF;
  }

  .btn-primary {
    background-color: #00376f;
    color: #BFBFBF;
    border-color: #646464;

    &:hover {
      background-color: #003d91;
      border-color: #646464;
      color: #BFBFBF;
    }
  }

  a.text-danger {
    color: #ad0008 !important;

    &:hover {
      color: #c30008 !important;
    }
  }

  .alert-success {
    color: #155724;
    background-color: #94ad9a;
    border-color: #67866e;
  }

  .alert-warning {
    color: #efbb04;
    background-color: #93876b;
    border-color: #7c6c5a;
  }

  .alert-info {
    color: #0bd2dd;
    background-color: #5a7176;
    border-color: #475f65;
    a {
      color: #ed6643
    }
  }
}