#unauth-single-form-page {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;

  form {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;

    .form-control {
      position: relative;
      box-sizing: border-box;
      height: auto;
      padding: 10px;
      font-size: 16px;
    }

    .form-control:focus {
      z-index: 2;
    }

    div.inputs {
      margin-bottom: 10px;

      & > div:not(:first-of-type):not(:last-of-type) input {
        border-radius: 0;
      }

      & > div:not(:last-of-type) input {
        border-bottom: none;
      }

      & > div:first-of-type input {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      & > div:last-of-type input {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}