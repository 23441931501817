@import "lib/bootstrap/scss/bootstrap";
//@import "lib/font-awesome/scss/fontawesome";

:root {
  --bs-body-bg: #f5f5f5;
  --bs-body-color: #232323;
  --bs-tertiary-bg: #d3d3d3
}

.table {
  --bs-table-color-state: var(--bs-body-color);
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

header {
  overflow: hidden;
  padding: 1em;
}

footer {
  text-align: center;
  font-size: 0.5rem;
  padding: 0.5rem;
}

.container-fluid > .row > .col {
  min-height: 100vh;
  position: relative;

  .container {
    padding-bottom: 0.8rem;
  }

  footer {
    padding: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0.8rem;
  }
}

.preserve-whitespace {
  white-space: pre-wrap;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.collabsible-wrapper {
  & > input {
    display: none;
  }

  & > label {
    display: block;
    width: 100%;
    cursor: pointer;

    h3:before {
      content: ' ';
      display: inline-block;

      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid currentColor;

      vertical-align: middle;
      margin-right: .7rem;
      transform: translateY(-2px);

      transition: transform .2s ease-out;
    }
  }

  & > div {
    max-height: 0;
    overflow: hidden;
    transition: max-height .25s ease-in-out;
  }

  & > input:checked + label + div {
    max-height: 100vh;
  }

  & > input:checked + label h3:before {
    transform: rotate(90deg) translateX(-3px);
  }
}

#setup-dns {
  td:first-child {
    white-space: nowrap;
  }

  td:last-child {
    word-break: break-all;
  }
}

@import "unauth-single-form";
@import "dark-theme";
